<template>
  <div class="shophome" :style="changeHover(navBackground)">
    <div class="shophome_box">
      <div class="swipers">
        <div class="left" :style="changeHover(navBackground)">
          <div class="product_class_list " v-for="(item ,index) in swiperList" :key="index"
            @click="handledetil(item,index,'1')">
            <div class="ellipsis">{{ item.categoryName}}</div>
            <div class="children_box">
              <div class="children_line" v-for="(ite,idx) in item.childrenList" :key="idx">
                <div class="chilren_second" @click.stop="handledetil(ite,idx,'2')">
                  {{ ite.categoryName }}</div>
                <div class="chilren_three">
                  <span v-for="(v,i) in ite.childrenList" :key="i"
                    @click.stop="handledetil(v,i,'3',item)">{{ v.categoryName }}</span>
                </div>
              </div>
              <div class="children_box_right_imgList">
                <div v-for="(v,i) in noticelist" :key="i" style="color: red;padding: 5px 0;height: 45px;">
                  <img :src="v.brandImgUrl" alt="" width="100" height="35" />
                </div>
              </div>
            </div>
            <div>
              <i class="el-icon-arrow-right"></i>
            </div>
          </div>
        </div>

        <div class="contents">
          <div class="content-top">
            <el-carousel>
              <el-carousel-item v-for="(item , i) in carousels" :key="i">
                <img :src="item.imgUrl" style="width: 100%; height: 100%;" @click="handlego(item)">
              </el-carousel-item>
            </el-carousel>
          </div>

          <div class="content-bto">
            <div v-for="(item,index) in advertises" :key="index" class="bto" @click="handlego(item)">
              <img :src="item.imgUrl" style="width: 100%; height: 100%;" />
            </div>
          </div>
          <!-- <div class="swiperitem" v-if="isswiperitem">
            <div class="swiper-box" v-for="(item,i) in swiperitemlist" :key="i">
              <div style="width: 20%; font-weight: 600; text-align: right; line-height: 40px; font-size: 12px; ">
                {{ item.categoryName }}</div>
              <div style="width: 79%; border-bottom: 1px solid #ededed; line-height: 40px; font-size: 12px;">
                <span v-for="(it,index) in item.childrenList" :key="index"
                  style="margin-left: 10px; color: #303135;font-size: 11px; "
                  @click="handledetil()">{{ it.categoryName }}</span>
              </div>
            </div>
          </div> -->
        </div>
        <div class="right">
          <el-card class="card1" style="height: 140px;margin-top: 5px;border-radius: 10px; "
            :style="{ borderColor: setcolr === 1 ? '#B87333' : setcolr === 2 ? '#E6E8FA' : setcolr === 3 ? '#FFFF00' : '#ededed' }"
            shadow="always">
            <div style="width: 100%; text-align: center;" v-if="!nickName">
              <div style="color: #FF6D87; text-decoration: underline;">注册会员尊享VIP折扣</div>
              <div style="margin-top: 30px;">
                <el-button type="primary" style="height: 20px; padding: 0 15px;" round @click="login">登陆</el-button>
                <el-button round style="height: 20px; padding: 0 15px;" @click="login('register')">注册</el-button>
              </div>
            </div>
            <div style="width: 100%; " v-else>
              <div style="display: flex;">
                <div style="width: 41%; color: #fff; font-weight: 600;">
                  <div class="icon_vip" style="">
                    <!-- :style="{ backgroundColor: setcolr === 1 ? '#B87333' : setcolr === 2 ? '#E6E8FA' : setcolr === 3 ? '#FFFF00' : 'initial' }" -->
                    <img class="icon_bg" src="@/assets/images/u923.svg" alt="">
                    <img class="icon_crown" src="@/assets/images/u924.svg" alt="" srcset="">
                    <span class="icon_text">
                      vip {{ setcolr }}
                    </span>

                  </div>
                </div>
                <img :src="avatarImg ? avatarImg : require('@/assets/images/user.png')" alt="user"
                  style="width: 32px;height: 32px;border-radius: 50%;display: inline-block;background: #E6F5FF;margin-top: 3px;">
              </div>
              <div class="ellipsis"
                style="color: #666666;; font-size: 14px; line-height: 40px;text-align: center;font-weight: bold;">
                {{customerName}}
              </div>
            </div>
          </el-card>
          <el-card shadow="always" style="height: 230px; margin-top: 15px;border-radius: 10px;color: #666666; ">
            <div class="ftsize-13">分销商公告：</div>
            <div style="height: 156px; overflow-y: auto;overflow-x: hidden; margin: 8px">
              <div style="margin-left: 3px;  overflow-wrap: break-word; line-height: 20px" class="gonggao"
                @click="handlenotice(item)" v-for="(item,i) in gonggaolist" :key="i">
                <div v-if="item.noticePlatform == 1" class="ftsize-12"">{{ item.noticeTitle }}</div>
              </div>
            </div>
          </el-card>
          <el-card shadow=" always" style="height: 140px; margin-top: 10px;border-radius: 10px;color: #666666;">
                  <div class="ftsize-13">平台公告：</div>
                  <div style="height: 60px; overflow-y: auto;overflow-x: hidden; margin: 8px">
                    <div style="margin-left: 3px;  overflow-wrap: break-word; line-height: 20px" class="gonggao"
                      @click="handlenotice(item)" v-for="(item,i) in gonggaolist" :key="i">
                      <div v-if="item.noticePlatform == 2" class="ftsize-12">{{ item.noticeTitle }}</div>
                    </div>
                  </div>
          </el-card>
        </div>

      </div>
      <div v-if="noticelist.length>0" class="notice">
        <el-card shadow="always" style="height: 100%; width: 100%;padding-bottom: 20px;border-radius: 10px;">
          <div style="width: 100%; height: 100%; text-align: center;">
            <div class="notice_title" style="font-size: 16px;">
              <span class="center">代理品牌</span>
              <span class="center" style="cursor:pointer;color:#666666;font-size: 13px;" @click="jumpUrl">
                << MORE>>
              </span>
            </div>
            <div class="brank" :style="{height:showAll?'fit-content':'150px'}">
              <div v-for="(item ,index) in noticelist" :key="index"
                style="width: 15%;margin-top: 20px;margin-left: 18px; cursor: pointer;">
                <el-card shadow="always" style="height: 150px; width: 90%; position: relative;border-radius: 10px;">
                  <div style="width: 100%; height: 80px; ">
                    <img :src="item.brandImgUrl ?item.brandImgUrl : require('@/assets/images/noneImages.svg') "
                      style="width: 100%; height: 80px;" />
                  </div>
                  <div
                    style="width: 100%;height: 25px; background-color: #ededed; text-align: center;color:#666666;font-size: 12px;line-height: 25px; ">
                    {{ item.brandName }}</div>
                </el-card>
              </div>
            </div>
          </div>

        </el-card>
      </div>
      <div class="goods">
        <div
          style="width: 100%; text-align: center; font-size: 16px;display: flex;align-items: center;justify-content: center; color:#666666;">
          <img src="../../assets/images/u925.svg" style="width: 25px;margin-right: 10px;" alt="hot"> 热销产品
        </div>
        <div style="width: 100%;">
          <div v-for="(item ,i) in productlist" :key="i" class="goods-content">
            <div class="goods-left">
              <div class="ftsize-14 ellipsis" :style="{background:navBackground}"
                style="width: 100%;height:50px;color: #fff;text-align: center;padding-top: 10px;">
                <div>{{ item.productCategoryLevel1 }}</div>
                <div class="ftsize-14" style="cursor: pointer;color: #fff">
                  << MORE>>
                </div>
              </div>
              <div style=" width: 100%; height: 220px;">
                <img v-if="item.productCategoryLevel1ImgUrl" :src="item.productCategoryLevel1ImgUrl "
                  style="width: 100%;height: 100%;">
                <img v-else :src="require('@/assets/images/noneImages.svg')"
                  style="width: 100%;height: 100%;border: 1px solid #cccccc63;">
              </div>
            </div>
            <div class="goods-right">
              <div v-for="(it,index) in item.productList" :key="index" class="center" @click="jumpScreen(it)">
                <div
                  style="width: calc(100% - 130px) ;height: 90%;line-height: 40px;margin: 10px; padding-left: 30px;padding-right: 10px;cursor: pointer;">
                  <div class="ellipsis" style="color: #ff0066;font-size: 13px;overflow: hidden">{{ it.brand }}
                    {{ it.series }}</div>
                  <div class="ftsize-13 ellipsis" style="margin-left: 10px;text-align: center;">{{ it.productCategory }}
                  </div>
                  <div class="ftsize-12 ellipsis"> <span style="color: #ffa800;">￥</span><span
                      style="color: #ff0066;">{{ it.benchmarkPrice }}元起</span></div>
                </div>
                <div style="width: 100px; height: 100px; margin: 10px; position: relative;">
                  <img v-if="it.img1Url" :src="it.img1Url" style="width: 100%;height: 100%;">
                  <img v-if="it.brandImgUrl" :src="it.brandImgUrl"
                    style="width: 42.5px;height: 25px;position: absolute;left: -21.25px;top: 0;border: none;">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="hot_tip">
      <img src="../../assets/images/u925.svg" style="width: 25px;" alt="hot">
      <p style="font-size: 19px;color: #666666;margin-left: 5px;">热销产品</p>
    </div>
    <div class="hot_product">
      <div class="ht_ytpe">
        <div :style="{background: navBackground}">
          <p>低压配电</p>
          <p>&lt;&lt;MORE&gt;&gt;</p>
        </div>
        <div>
          <img src="../../assets/images/u556.svg" alt="prologo">
        </div>
      </div>
      <div class="ht_proinfo">
        <div @click="jumpScreen">productInfo</div>
      </div>
    </div> -->
    </div>
    <!--    通用底部-->
    <bottom-info></bottom-info>
  </div>
</template>

<script>


import bottomInfo from "@/views/job/bottomInfo.vue";
import {
  getInfo,
  getProduct,
  getBrand,
  getCategory,
  getNotice
} from '@/api/mallindex'
import {
  getUserInfo
} from "@/utils/api";


export default {
  name: "shopHome",
  components: {
    bottomInfo
  },
  data() {
    return {
      mallList: {},
      isactive: 100,
      isswiperitem: false,
      setcolr: 0,
      navOffsetTop: 0,
      //  背景色
      navBackground: '#0079FE',
      nowFlag: true,
      nickName: null,
      lastTabName: null,
      swiperList: [],
      carousels: [],
      advertises: [],
      noticelist: [],
      productlist: [],
      swiperitemlist: [],
      gonggaolist: [],
      distributor: '',
      showAll: false,// 点击more展示全部
      customerName: "分销商城",
    }
  },

  created() {
    this.mallList = this.$store.state.userInfo
  },
  mounted() {

    // this.extractLastPathSegment()
    this.getlist()

    this.getUserPhone()

    // this.loadDevice()
  },
  beforeRouteUpdate(to, from, next) {
    // 在路由更新时滚动到页面顶部
    window.scrollTo(0, 0);
    // 继续路由切换
    next();
  },
  methods: {
    changeHover(color) {
      return {
        "--bgColor": color + '7f',
        "--color-hover": color
      }
    },
    extractLastPathSegment() {
      const currentUrl = window.location.href;
      const url = new URL(currentUrl);
      const codes = this.$Cookie.get('distributor')
      if (codes) {
        this.getlist()
      }
      const lastPart = url.searchParams.get('code');
      if (lastPart) {
        this.$Cookie.set('distributor', lastPart)
        this.getlist()
      }
    },
    getlist() {
      const lastPart = this.$Cookie.get('distributor')
      let res = {
        data: this.mallList
      }
      if (Object.keys(res.data).length !== 0) {
        if (res.data?.systemConfig?.mallSwitch === 0) {
          this.$router.push("/error")
        } else {
          if (res.data?.systemConfig?.themeColor) this.navBackground = res.data?.systemConfig?.themeColor
          this.$Cookie.set("navBackground", JSON.stringify(res.data?.systemConfig?.themeColor))
          localStorage.setItem("navBackground", JSON.stringify(res.data?.systemConfig?.themeColor))

          const keyToCheck = 'imgId'
          const arr = JSON.parse(JSON.stringify(res.data?.carousels))
          if (arr && arr.length > 0) {
            this.carousels = arr.filter(obj => obj[keyToCheck] !== '' && obj[keyToCheck] !== 0 && obj[keyToCheck] !== null)
          } else {
            this.carousels = res.data?.carousels
          }
          this.advertises = res.data?.advertises
          this.distributorId = res.data?.distributorId
          this.distributorCompanyId = res.data?.distributorCompanyId
          const branddata = {
            distributorId: this.distributorId,
            distributorCompanyId: this.distributorCompanyId || 0
          }

          getBrand(branddata).then(re => {
            let agentBrands = res.data?.agentBrands || []
            let arr = []
            agentBrands.map(item => {
              let idx = re.data.findIndex(d => d.brandId == item.correlationId)
              if (idx !== -1) {
                arr.push(re.data[idx])
              }
            })
            this.noticelist = arr
          })

          getCategory(branddata).then(re => {
            this.swiperList = re.data
          })

          getNotice({ distributorId: this.distributorId, }).then(re => {
            this.gonggaolist = re.data
          })

          let user = this.$Cookie.get('user_info')
          if (user) {
            const tempUserInfo = JSON.parse(user)
            const data = {
              distributorId: res.data.distributorId,
              customerId: tempUserInfo.customerId
            }
            getProduct(data).then(r => {
              // console.log(r, '12333')
              this.productlist = r.data
            })
          } else {
            const data = {
              distributorId: res.data.distributorId,
            }
            getProduct(data).then(r => {
              // console.log(r, '12333')
              this.productlist = r.data
            })
          }



        }
      }
      // getInfo(lastPart).then(res => {

      // }).catch(err => {

      // })

    },
    // 获取用户手机号
    getUserPhone() {
      this.userLoginPhone = this.$Cookie.get('userName')
      if (this.userLoginPhone) {
        let userArray = {
          phone: this.userLoginPhone,
          distributorId: this.$Cookie.get('distributorId'),
          // access_token:this.$Cookie.get('accessToken')
        }
        getUserInfo(userArray).then((res) => {
          if (res.code === 200) {
            this.$Cookie.set('user_info', JSON.stringify(res.data))
            this.customerName = res.data?.customerName
            this.tabShow = true
            let user = this.$Cookie.get('user_info')
            this.$store.commit("SET_PHONE_USER_INFO", res.data)
            const tempUserInfo = JSON.parse(user)
            this.nickName = tempUserInfo.nickName
            this.setcolr = tempUserInfo.customerLevel
            this.avatarImg = tempUserInfo.avatarUrl
          }
        })
      }


    },
    /**
     * @Date:2024/3/29
     * @Author:wt
     * @Function:获取存储页面信息模块
     * @param:无
     */
    loadDevice() {
      let query = JSON.parse(this.$Cookie.get('location'))
      // console.log(query);
      if (query) {
        this.$router.push({
          path: "/website/shophome/productscreening",
          // query: {
          //   // product: queryParamss
          // }
        })
      }
    },


    // 展示所有产品
    handleswiperlist(item, i) {
      this.isactive = i
      this.swiperitemlist = item.childrenList
      this.isswiperitem = true
    },
    //跳转产品筛选
    handledetil(item, index, type, firstItem) {
      let data = {
        categoryName: item.categoryName
      }
      if (type == '1') {
        data.type = type
        data.firstId = item.productCategoryId
        data.secondId = ''
        data.thirdId = ''
      } else if (type == '2') {
        data.type = type
        data.firstId = item.parentCategoryId
        data.secondId = item.productCategoryId
        data.thirdId = ''
      } else if (type == '3') {
        data.type = type
        data.firstId = firstItem.productCategoryId
        data.secondId = item.parentCategoryId
        data.thirdId = item.productCategoryId
      }


      let parObj = JSON.stringify(data)
      this.$router.push({
        path: "/website/shophome/productscreening",
        query: {
          type: 'list',
          product: parObj
        }
      })
    },
    // 热销产品跳转 
    jumpScreen(item) {
      // 筛选信息保存二次利用
      //  const pathProductData = JSON.stringify(item)
      // this.$Cookie.set('pathProductData',pathProductData)
      // this.$router.replace("/website/shophome/productscreening");
      // const routePam = {
      //   marque: item.marque,
      //   productCategoryId: item.productCategoryId
      // }
      let data = {
        firstId: item.productCategoryLevel1Id,
        productCategoryId: item.productCategoryId,
        productId: item.productId,
        marque: item.marque,
        series: item.series,
        brand: item.brandId,
        brandName: item.brand
      }

      let parObj = JSON.stringify(data)
      this.$router.push({
        path: "/website/shophome/productscreening",
        query: {
          type: 'hot_sale',
          product: parObj
        }
      })
    },
    handlenotice(item) {
      const routePam = {
        noticeId: item.noticeId
      }
      if (item.pdfUrl) {
        window.open(item.pdfUrl)
      } else {
        let parObj = JSON.stringify(routePam)
        this.$router.push({
          path: "/website/noticedetail",
          query: {
            product: parObj
          }
        })
      }
    },
    login(type) {
      if (type === 'register') {
        this.$router.push({ path: '/login', query: { from: 'register', type: "mall" } });
      } else {
        this.$router.push({ path: '/login', query: { type: "mall" } });
      }
      // this.showFlag = 5
    },
    // 轮播图跳转
    handlego(item) {
      // console.log(item);
      if (item.details.length > 0) {
        const data = item.details
        let parObj = JSON.stringify(data)
        this.$router.push({
          path: "/website/shophome/productscreening",
          query: {
            type: 'img',
            product: parObj
          }
        })
      }
    },

    // 跳转到更多品牌
    jumpUrl() {
      this.$router.push("/website/shophome/brand")
    }
  },
  watch: {
    $route(to, from) {
      // 在路由变化时进行一些操作，如果需要的话
      if (to.name === 'shopHome') {
        this.nowFlag = true
      } else {
        this.nowFlag = false
      }
    },
    '$store.state.userInfo': {
      handler(val) {
        this.mallList = val
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.shophome {
  width: 1360px;
  margin: 0 auto;
  transform: translateX(3px);

  .menu {
    width: 100%;
    height: 500px;
    border: 1px dashed black;
  }

  .swipers {
    width: 100%;
    height: 540px;
    display: flex;
    justify-content: space-between;

    .left {
      width: 200px;
      height: 100%;
      background-color: var(--bgColor);
      color: #fff;
      position: relative;
      .product_class_list {
        width: 100%;
        height: 40px;
        color: #fff;
        font-size: 13px;
        line-height: 40px;
        display: flex;
        padding: 0 15px;
        justify-content: space-between;
        cursor: pointer;
      }

      .children_box {
        padding-right: 150px;
        position: absolute;
        top: 0;
        left: 200px;
        z-index: 98;
        width: 920px;
        height: 540px;
        display: none;
        background-color: rgba($color: #fff, $alpha: 0.95);
        .children_line {
          height: fit-content;
          display: flex;
          .chilren_second {
            color: #000000;
            font-weight: 600;
            font-size: 13px;
            cursor: pointer;
            padding: 0 20px;
            height: fit-content;
            line-height: 25px;
            width: 150px;
            text-align: right;
            &:hover {
              color: var(--color-hover);
            }
          }
          .chilren_three {
            display: flex;
            height: fit-content;
            width: calc(100% - 125px);
            color: #5d5c5c;
            font-size: 13px;
            cursor: pointer;
            line-height: 25px;
            display: flex;
            flex-wrap: wrap;
            &:hover {
              color: var(--color-hover);
            }
            span {
              display: block;
              width: fit-content;
              margin-right: 10px;
            }
          }
        }
        .children_box_right_imgList {
          overflow: hidden;
          position: absolute;
          right: 0;
          top: 0;
          height: 100%;
          width: 100px;
        }
      }
      .product_class_list:hover {
        background-color: var(--color-hover);
      }
      .product_class_list:hover .children_box {
        display: block;
      }

      .active {
        background-color: #008efe;
      }
    }

    .contents {
      width: 910px;
      height: 100%;
      position: relative;

      .content-top {
        width: 100%;
        height: 390px;
        // background-color: #008EFE;
        position: relative;
        overflow: hidden;
        ::v-deep .el-carousel {
          .el-carousel__container {
            height: 390px;
          }
        }
      }

      .content-bto {
        width: 100%;
        height: 140px;
        margin-top: 10px;
        display: flex;
        justify-content: space-between;

        .bto {
          width: 33%;
          height: 100%;
          position: relative;
          // background-color: #008efe;

          .el-upload--picture-card {
            background-color: #008efe;
            width: 100%;
            height: 140px !important;
          }

          .img-slot {
            width: 100%;
            height: 140px !important;
          }

          .tip {
            width: 30px;
            height: 15px;
            margin-top: 5px;
            text-align: center;
            background-color: #fff;
            border-radius: 8px;
            position: absolute;
            left: 45%;
            bottom: 10px;
          }

          .tip:hover {
            border: #008efe;
            color: #008efe;
          }
        }
      }

      .swiperitem {
        width: 102%;
        height: 100%;
        position: absolute;
        top: 0;
        left: -10px;
        z-index: 99;
        background-color: rgba($color: #fff, $alpha: 0.98);

        .swiper-box {
          width: 100%;
          padding: 0 10px;
          display: flex;
          justify-content: space-between;
          cursor: pointer;
        }
      }
    }

    .right {
      width: 230px;
      height: 100%;

      .gonggao {
        div {
          color: #555555;
          &:hover {
            cursor: pointer;
            color: var(--color-hover) !important;
            text-decoration: underline;
          }
        }
      }
      ::v-deep .card1 {
        .el-card__body {
          height: 100%;
          display: flex;
          align-items: center;
        }
      }
    }
  }

  .notice {
    width: 100%;

    padding: 20px 0;
    .notice_title {
      display: flex;
      flex-direction: column;
      align-items: center;
      .center {
        font-size: 16px;
        color: #666666;
        width: fit-content;
      }
    }
    .brank {
      width: 100%;

      display: flex;
      flex-wrap: wrap;
      align-content: flex-start;
    }
  }

  .goods {
    width: 100%;
    padding: 20px 0 0 0;
    font-size: 12px;

    .goods-content {
      width: 100%;
      height: 270px;
      margin-top: 20px;
      display: flex;

      .goods-left {
        width: 200px;
        height: 100%;
      }

      .goods-right {
        width: calc(100% - 200px);
        height: 100%;
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;
        background: transparent;

        .center {
          font-size: 16px;
          color: #666666;
          padding: 0 10px;
          width: 386px;
          height: 50%;
          background: #ffffff;
          border: 1px solid #ebeef5;
          display: flex;
        }
      }
    }
  }

  .acting_brand {
    width: 100%;
    height: 400px;
    margin-top: 10px;
    background: white;
    border-radius: 10px;
  }

  .hot_tip {
    width: 100%;
    height: 80px;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
  }

  .hot_product {
    width: 100%;
    height: 355px;
    border: 1px solid #ebeef5;
    background: white;
    display: flex;
    flex-wrap: nowrap;

    .ht_ytpe {
      width: 17%;
      height: 100%;

      div:nth-child(1) {
        height: 20%;
        color: white;
        font-size: 17px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        /* 垂直方向上按顺序排列 */
        margin: 0;
        padding: 0;

        p {
          width: 100%;
          text-align: center;
        }
      }

      div:nth-child(2) {
        height: 80%;
        width: 100%;

        img {
          width: 102%;
          height: 100%;
        }
      }
    }

    .ht_proinfo {
      width: 83%;
      height: 100%;
      background: #98c7fc;
      display: flex;
      flex-wrap: wrap;

      div {
        width: 33%;
        height: 50%;
        background: white;
        cursor: pointer;
      }
    }
  }
}

.icon_vip {
  width: 50px;
  text-align: center;
  height: 20px;
  border-radius: 10px;
  position: relative;
  .icon_bg {
    width: 50px; /* 设置你需要的宽度 */
    height: 20px; /* 设置你需要的高度 */
  }
  .icon_crown {
    width: 13px;
    height: 13px;
    position: absolute;
    left: 3px;
  }
  .icon_text {
    color: #ffffff;
    position: absolute;
    right: 7px;
    line-height: 16px;
    font-size: 10px;
    font-style: italic;
  }
}
</style>
