export const ORDER_TYPES = [{
  value: 1,
  text: '客户订单'
}, {
  value: 2,
  text: '项目订单'
}, {
  value: 3,
  text: '次品订单'
}, {
  value: 4,
  text: '商城订单'
}, {
  value: 5,
  text: '样机订单'
}, {
  value: 6,
  text: '历史订单'
}]

export const ORDER_STATUS = [{
  value: 1,
  text: '客户用印'
}, {
  value: 2,
  text: '我方用印'
}, {
  value: 3,
  text: '超信审批'
}, {
  value: 4,
  text: '预付确认'
}, {
  value: 5,
  text: '无货采购'
}, {
  value: 6,
  text: '有货待排'
}, {
  value: 7,
  text: '发款确认'
}, {
  value: 8,
  text: '排发完成'
}, {
  value: 9,
  text: '发货完成'
}, {
  value: 10,
  text: '全部签收'
}, {
  value: 11,
  text: '订单完成'
}, {
  value: 12,
  text: '尾款待收'
}, {
  value: 13,
  text: '订单取消'
}, {
  value: 14,
  text: '发货取消'
}, {
  value: 15,
  text: '取消中'
}, {
  value: 16,
  text: '订单修改'
}, {
  value: 17,
  text: '修改中'
},]

export const EXECUTE_STATUS = [{
  value: 1,
  text: '待执行'
}, {
  value: 2,
  text: '待采购'
}, {
  value: 3,
  text: '待入库'
}, {
  value: 4,
  text: '已采购'
}, {
  value: 5,
  text: '未全入'
}, {
  value: 6,
  text: '已入库'
}, {
  value: 7,
  text: '待排发'
}, {
  value: 8,
  text: '待出库'
}, {
  value: 9,
  text: '已出库'
}, {
  value: 10,
  text: '已签收'
}]
export const RETURN_ORDER_STATUS = [{
  value: 1,
  text: '等待出库'
}, {
  value: 2,
  text: '收款确认'
}, {
  value: 3,
  text: '退货完成'
}, {
  value: 4,
  text: '取消退货'
}]

