import api from "@/utils/request";

//售后服务获取工单编号
// export const workOrderNo = (data) =>{
//     const queryParams = new URLSearchParams(data).toString();
//     return api.get(`/service/number?${queryParams}`)
// }
// 获取产品列表
export const getProductList = (data) => api.post(`/product/search`,data)
// 获取产品详情
export const conditionProductList = (data) => api.post(`/product/info/condition`,data)
// 加入购物车
export const joinCar = (data) => api.post(`/shopping/cart/batch/save`,data)
// 获取分销商所有品牌
export const allBrand = (data) => api.post(`/product/brands`,data)
export const getAllBrand = (data) => api.post(`/product/selectBrandByCategory`,data)
// 获取所有的系列
export const allSeries = (data) =>{
    const queryParams = new URLSearchParams(data).toString();
    return api.get(`/product/series?${queryParams}`)
}
export const getAllseries = (data) => api.post(`/product/selectSeriesByCategory`,data)

// 查询购物车数据
export const shoppingCar = (data) => api.post(`/shopping/cart/list`,data)
// 删除购物车数据
export const shoppingCarDelete = (data) => api.post(`/shopping/cart/delete`,data)

