<template>
  <div class="product_screen" :style="changeHover(navBackground)">
    <div style="height: 100%;" v-if="productScreenShow">
      <!--面包屑-->
      <div class="bread">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/website/shophome/shop' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item>产品筛选</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <!--  品牌筛选-->
      <div class="searchproduct">
        <div class="search_brand">
          <div class="search_brand_left">
            一级分类
          </div>
          <div class="search_brand_right">
            <div class="search_brand_right_text" v-for="(item,index) in topCategory" :key="index">
              <span class="ftsize-13" :class="item.categoryName == activeObj.topActive ? 'active' :''"
                @click="categoryChange(item,index,'top')">{{item.categoryName}}</span>
            </div>
          </div>
        </div>
        <div class="search_brand" v-if="secondList.length>0">
          <div class="search_brand_left">
            二级分类
          </div>
          <div class="search_brand_right">
            <div class="search_brand_right_text" v-for="(item,index) in secondList" :key="index">
              <span class="ftsize-13" :class="item.categoryName == activeObj.secondActive ? 'active' :''"
                @click="categoryChange(item,index,'second')">{{item.categoryName}}</span>
            </div>
          </div>
        </div>
        <div class="search_brand" v-if="categoryList.length>0">
          <div class="search_brand_left">
            品类
          </div>
          <div class="search_brand_right">
            <div class="search_brand_right_text" v-for="(item,index) in categoryList" :key="index">
              <span :class="item.categoryName == activeObj.categoryActive ? 'active' :''"
                @click="categoryChange(item,index,'category')">{{item.categoryName}}</span>
            </div>
          </div>

        </div>
        <div class="search_brand"  v-if="brand.length>0">
          <div class="search_brand_left">
            品牌
          </div>
          <div class="search_brand_right">
            <div v-for="(item,index) in brand" :key="item.brandId" class="search_brand_right_text">
              <span class="ftsize-13" :class="item.checked? 'active' :''"
                @click="categoryChange(item,index,'brand')">{{ item.brandName }}</span>
            </div>
          </div>
        </div>
        <div class="search_brand search_series" v-if="seriesArray.length>0">
          <div class="search_brand_left ">
            系列
          </div>
          <div class="search_brand_right  search_series_right">
            <div v-for="(item,index) in seriesArray" :key="index" class="search_brand_right_text ">
              <span class="ftsize-13" :class="item.checked? 'active' :''"
                @click="categoryChange(item,index,'series')">{{ item.seriesName }}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="sort_catena">
        <div class="sort">
          <div class="sort_radio">
            <span style="background: var(--bgColor)">排序：</span>
            <span class="sort_hover" :class="searchFrom.sales !=='' ? 'searchActive':''"
              @click="searchRadio('sales')">销量</span>
            <span class="sort_hover" :class="searchFrom.price !=='' ? 'searchActive':''"
              @click="searchRadio('price')">价格</span>
          </div>
          <div>
            <el-checkbox v-model="spotsStatus" style="margin: 0 10px 0 20px;font-size: 17px"
              @change="handleHuoq($event,'spots')">现货</el-checkbox>
            <i style="font-size: 13px;color: #606266">/</i>
            <el-checkbox v-model="forwardStatus" style="font-size: 17px;margin: 0 0 0 15px;"
              @change="handleHuoq($event,'forward')">期货</el-checkbox>
            <el-button type="primary" plain round size="mini" style="margin-left: 20px;"
              @click="reloadSearch()">重置搜索</el-button>
          </div>
        </div>
      </div>
      <!--  表格 -->
      <div>
        <el-table class="detail_table" :data="tableData" style="width: 100%;height: 700px;" max-height="700">
          <el-table-column align="center" prop="date" label="产品信息" min-width="30%">
            <template slot-scope="scope">
              <div class="table_proiimg " @click="productJump(scope.row)">
                <div class="proImg">
                  <img v-if="scope.row.img1Url || scope.row.img2Url || scope.row.img3Url"
                    :src="scope.row.img1Url || scope.row.img2Url || scope.row.img3Url"
                    style="width: 100%;height: 68px;margin-top: 1px;" alt="img">
                  <img v-else :src="noneImages" style="width: 100%;height: 68px;" alt="img"></img>
                  <img v-if="scope.row.brandImgUrl" :src="scope.row.brandImgUrl "
                    style="width: 100%;height: 40px;margin-top: 1px;" alt="img">
                  <img v-else :src="noneImages" style="width: 100%;height: 40px;margin-top: 1px;" alt="img"></img>
                </div>
                <div class="table_proinfo ">
                  <p class="order_num">
                    <span class="order_title">订货号</span>：
                    <span class="order_span">{{ scope.row.productOrderNumber}}</span>
                  </p>
                  <p class="order_num">
                    <span class="order_title">型号</span>：
                    <span class="model_span">{{ scope.row.marque}}</span>
                  </p>
                  <p class="order_num">
                    <span class="order_title">包装规格</span>：
                    <span class="order_span">{{ scope.row.minPackageNum}}{{scope.row.unit}}/箱(盒)</span>
                  </p>
                  <p class="order_num">
                    <span class="order_title">市场价</span>：
                    <span class="order_span">￥<del>{{ scope.row.recommendPrice}}/{{ scope.row.unit }}</del> </span>
                  </p>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column align="left" prop="name" label="规格描述" min-width="15%">
            <template slot-scope="scope">
              <div style="font-size: 12px">{{scope.row.productDesc}}</div>
            </template>
          </el-table-column>
          <el-table-column align="center" min-width="10%" label="货期">
            <template slot-scope="scope">
              <div style="font-size: 12px;" v-if="scope.row.deliveryTerm >= 0 &&   scope.row.productInventorySum>0">
                <span style="background: #2DD5A5;padding: 5px 20px;color: white">现货</span>
              </div>
              <div style="font-size: 12px;" v-else>
                <span style="background: #FD5366;padding: 5px 20px;color: white">{{ scope.row.deliveryTerm }}天</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" min-width="10%" label="库存(个)">
            <template slot-scope="scope">
              <div style="font-size: 12px;">库存：{{scope.row.productInventorySum}}</div>
            </template>
          </el-table-column>
          <el-table-column align="center" min-width="10%" label="产品价格">
            <template slot-scope="scope">
              <div v-if="scope.row.benchmarkPrice" style="font-size: 12px;color:#FF0066;">
                <span style="color: #FED530;font-size: 15px">￥</span>
                {{scope.row.benchmarkPrice}}
              </div>
              <span v-else> 请咨询销售</span>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="address" min-width="13%" label="采购数量">
            <template slot-scope="scope">
              <div style="display: flex;justify-content: center;">
                <el-input-number size="mini" v-model="scope.row.purchaseAmount" @change="handleProductTotal(scope.row)"
                  :min="0" :max="9999" label="描述文字"></el-input-number>
              </div>
              <div style="margin-top: 10px;display: flex;justify-content: center;">
                <el-button @click="joinShopCar(scope.row)" class="gwc" type="primary" size="small" round>加购物车<i
                    class="el-icon-shopping-cart-2"></i></el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- <div style="background: white;width: 100%;height: 100px;text-align: center;line-height: 100px;">
        <el-button @click="joinShopCar" style="width: 225px;height: 43px;font-size: 18px" type="primary" round>加购物车<i
            class="el-icon-shopping-cart-2"></i></el-button>
      </div> -->
    </div>
    <!--  三级路由出口-->
    <!-- <router-view v-else /> -->
    <!-- 底部 -->
    <bottom-info></bottom-info>
  </div>
</template>

<script>
import bottomInfo from "@/views/job/bottomInfo.vue";
import detailjump from "@/views/screen/detailjump.vue";
import { getProductList, joinCar, getAllBrand, getAllseries } from "@/api/productscreening";
import noneImages from '@/assets/images/noneImages.svg'
import {
  getCategory,
} from '@/api/mallindex'
import { mapState } from "vuex"
import { hex } from "js-md5";
export default {
  name: "productscreening",
  components: {
    bottomInfo,
    //  detailjump 
  },
  data() {
    return {
      noneImages: noneImages,
      brand: [], // 品牌列表
      seriesArray: [], //系列列表

      productScreenShow: true,
      //   表格数据
      tableData: [],
      routerKey: '',

      //  背景色
      navBackground: '#0079FE',
      // 购物车数据保存
      // carData:[],
      topCategory: [],  // 整体分类数据 //  顶级数据
      secondList: [],   // 二级分类数据
      categoryList: [], // 品类
      activeObj: {
        topActive: null,  // 顶级选择
        secondActive: null,  // 二级选择
        categoryActive: null,  // 品类选择
        brandActiveList: [], // 品牌选择
        seriesActiveList: [], // 系列选择  
        marqueList: [], // 型号选择 图片进入时有
      },
      searchFrom: {
        sales: "",
        price: "",
      },
      spotsStatus: false,
      forwardStatus: false,
      executionQuantity: 0,
      searchCriteriaMarque: null // 外部搜索条件直接搜索
    }
  },
  computed: {
    ...mapState(['userInfo']),
    countHeight() {
      let a = '577'
      let height = `calc(100vh - ${(a) + 'px'})`
      return height
    },
  },
  watch: {
    '$route'(to, from) {
      var that = this
      if (to.name === 'productscreening') {
        that.productScreenShow = true
        that.incommingData()
      }
    },
    executionQuantity(newVal) {
      if (newVal >= 3) {
        this.incommingData()
      }
    }
  },
  created() {
    window.scrollTo(0, 0);
  },
  mounted() {
    let backColor = localStorage.getItem('navBackground')
    this.navBackground = backColor ? JSON.parse(backColor) : '#0079FE'
    this.init()
  },
  methods: {
    init() {
      this.executionQuantity = 0
      this.getCategoryList()
      this.getAllBrand('first')
      this.getAllSeries('first')
    },

    changeHover(color) {
      return {
        "--bgColor": color + '4d',
        "--color-hover": color
      }
    },
    incommingData() {
      var that = this
      let query = that.$route.query
      that.reloadSearch("reload")
      if (query.type == 'search') {
        // 搜索直接进
        let queryParams = JSON.parse(query.product)
        that.searchCriteriaMarque = queryParams.marque.trim()

      } else if (query.type == 'list') {
        // 点击导航菜单进
        let queryParams = JSON.parse(query.product)
        if (that.topCategory.length > 0) {
          let idx = that.topCategory.findIndex(d => d.productCategoryId == queryParams.firstId)
          if (idx !== -1) that.categoryChange(that.topCategory[idx], idx, 'top', "click")
        }
        if (that.secondList.length > 0) {
          let idx = that.secondList.findIndex(d => d.productCategoryId == queryParams.secondId)
          if (idx !== -1) that.categoryChange(that.secondList[idx], idx, 'second', "click")
        }
        if (that.categoryList.length > 0) {
          let idx = that.categoryList.findIndex(d => d.productCategoryId == queryParams.thirdId)
          if (idx !== -1) that.categoryChange(that.categoryList[idx], idx, 'category', "click")
        }

      } else if (query.type == 'hot_sale') {
        // 点击下面热销产品进
        let queryParams = JSON.parse(query.product)
        if (that.topCategory.length > 0) {
          let idx = that.topCategory.findIndex(d => d.productCategoryId == queryParams.firstId)
          if (idx !== -1) that.categoryChange(that.topCategory[idx], idx, 'top', "click")
        }
        let brandIdx = that.brand.findIndex(d => d.brandId == queryParams.brand)
        that.brand[brandIdx].checked = true
        that.activeObj.brandActiveList.push(queryParams.brand)
        let seriesIdx = that.seriesArray.findIndex(d => d.seriesName == queryParams.series)
        that.seriesArray[seriesIdx].checked = true
        that.activeObj.seriesActiveList.push(queryParams.series)

      } else if (query.type == 'img') {
        // 点击图片进入
        let queryParams = JSON.parse(query.product)
        queryParams.map(item => {
          let brandIdx = that.brand.findIndex(d => d.brandId == item.brand)
          that.brand[brandIdx].checked = true
          if (that.activeObj.brandActiveList.indexOf(item.brand) === -1) {
            that.activeObj.brandActiveList.push(item.brand)
          }
          let seriesIdx = that.seriesArray.findIndex(d => d.seriesName == item.series)
          that.seriesArray[seriesIdx].checked = true
          if (that.activeObj.seriesActiveList.push(item.series) === -1) {
            that.activeObj.seriesActiveList.push(item.series)
          }
          if (that.activeObj.marqueList.push(item.marque) === -1) {
            that.activeObj.marqueList.push(item.marque)
          }
        })
      }

      that.getList()
    },
    //搜索的
    getList() {
      var that = this
      let orderType = that.searchFrom.sales == 'sales' ? 1 : null //销量还是价格
      if (!orderType) {
        orderType = that.searchFrom.price == 'price' ? 2 : null
      }
      let deliveryTerm = that.spotsStatus ? 1 : (that.forwardStatus ? 2 : "") // 2期货or 1现货
      let brandList = []
      that.activeObj.brandActiveList.map(item => brandList.push(item))
      let seriesList = []
      that.activeObj.seriesActiveList.map(item => seriesList.push(item))
      let marqueList = []
      that.activeObj.marqueList.map(item => marqueList.push(item))
      let userInfo = that.$Cookie.get('user_info') ? JSON.parse( that.$Cookie.get('user_info')) : ''
      let searchData = {
        distributorId: that.$Cookie.get('distributorId'),
        distributorCompanyId: that.$Cookie.get('distributorCompanyId'),
        customerId: userInfo?.customerId,
        orderType: orderType, // 销量
        deliveryTerm: deliveryTerm, // 期货

        primaryCategory: that.activeObj.topActive, // 一级分类
        secondaryCategory: that.activeObj.secondActive, // 二级分类
        category: that.activeObj.categoryActive, // 品类
        condition: that.searchCriteriaMarque, // 外部搜索蓝 搜索条件
        brandIdList: brandList,   //  品牌
        seriesList: seriesList, // 系列
        marqueList: marqueList, // 型号
        
        // productCategoryId: routeParam.productCategoryId
      }
      // console.log(searchData);
      getProductList(searchData).then(res => {
        that.tableData = []
        if (res.code === 200) {
          let tempData = res.data
          if (tempData && tempData.length > 0) {
            tempData.map(item => {
              that.tableData.push({
                ...item,
                'purchaseAmount': 0
              })
            })
          }
        }
      })
    },
    // 重置搜索
    reloadSearch(type) {
      var that = this
      that.secondList = []
      that.categoryList = []
      that.brand.map(item => item.checked = false)
      that.seriesArray.map(item => item.checked = false)
      Object.assign(that.activeObj, that.$options.data().activeObj)
      Object.assign(that.searchFrom, that.$options.data().searchFrom)
      that.spotsStatus = false
      that.forwardStatus = false
      // that.searchCriteriaMarque = null
      if (type) {
      } else {
        that.getList()
      }
    },
    /**
    * Author:wt
    * Date:2024/03/27
    * Function:获取分销商的品牌
    * @param 无
    */
    getAllBrand(type) {
      var that = this
      const tempObj = {
        // distributorCompanyId: this.$Cookie.get('distributorCompanyId'),
        distributorId: this.$Cookie.get('distributorId'),
        primaryCategory: that.activeObj.topActive, // 一级分类
        // primaryCategory: '低压传动', // 一级分类
        secondaryCategory: that.activeObj.secondActive, // 二级分类
        category: that.activeObj.categoryActive, // 品类

      }
      getAllBrand(tempObj).then((res) => {
        if (res.code === 200) {
          res.data.map(item => item.checked = false)
          this.brand = res.data
          if(type === 'first'){
            this.executionQuantity += 1
          }
        }
      })
    },
    /**
     * Author:wt
     * Date:2024/03/27
     * Function:获取分销商的系列
     * @param 无
     */
    getAllSeries(type) {
      var that = this
      let brandList = []
      that.activeObj.brandActiveList.map(item => brandList.push(item))
      const data = {
        distributorId: this.$Cookie.get('distributorId'),
        primaryCategory: that.activeObj.topActive, // 一级分类
        // primaryCategory: '低压传动', // 一级分类
        secondaryCategory: that.activeObj.secondActive, // 二级分类
        category: that.activeObj.categoryActive, // 品类
        brandIdList: brandList,   //  品牌
      }
      getAllseries(data).then(res => {
        if (res.code === 200) {
          let arr = []
          res.data.map(item => {
            let obj = {
              checked: false,
              seriesName: item
            }
            arr.push(obj)
          })
          this.seriesArray = arr
          if(type === 'first'){
            this.executionQuantity += 1
          }
        }
      })
    },
    // 获取一级二级品类
    getCategoryList() {
      const data = {
        distributorId: this.$Cookie.get('distributorId'),
        distributorCompanyId: this.$Cookie.get('distributorCompanyId')
      }
      getCategory(data).then(res => {
        this.topCategory = res.data
        this.executionQuantity += 1
      })
    },
    // 一级二级品类点击的时候
    categoryChange(item, index, type, status) {
      var that = this
      that.activeObj.marqueList = []
      if (type == 'top') { //  一级
        that.activeObj.topActive = item.categoryName
        that.activeObj.secondActive = null
        that.activeObj.categoryActive = null
        that.secondList = item.childrenList
        that.categoryList = []

        that.activeObj.brandActiveList = []
        that.activeObj.seriesActiveList = []
        this.getAllBrand()
        this.getAllSeries()
      } else if (type == "second") { // 二级
        that.activeObj.secondActive = item.categoryName
        that.categoryList = item.childrenList
        that.activeObj.categoryActive = null

        that.activeObj.brandActiveList = []
        that.activeObj.seriesActiveList = []
        this.getAllBrand()
        this.getAllSeries()
      } else if (type == 'category') { // 品类
        that.activeObj.categoryActive = item.categoryName

        that.activeObj.brandActiveList = []
        that.activeObj.seriesActiveList = []
        this.getAllBrand()
        this.getAllSeries()
      } else if (type == 'brand') { // 品牌
        item.checked = !item.checked
        let idx = that.activeObj.brandActiveList.findIndex(d => d == item.brandId)
        if (idx == -1) {
          that.activeObj.brandActiveList.push(item.brandId)
        } else {
          that.activeObj.brandActiveList.splice(idx, 1)
        }
        this.getAllSeries()
      } else if (type == 'series') { // 系列
        item.checked = !item.checked
        let idx = that.activeObj.seriesActiveList.findIndex(d => d == item.seriesName)
        if (idx == -1) {
          that.activeObj.seriesActiveList.push(item.seriesName)
        } else {
          that.activeObj.seriesActiveList.splice(idx, 1)
        }
      }
      if (status == "click") {

      } else {
        that.getList()

      }
    },
    // 销量搜索
    searchRadio(type) {
      var that = this
      if (type == 'sales') {
        that.searchFrom.sales = that.searchFrom.sales == '' ? 'sales' : ''
        that.searchFrom.price = ''

      } else if (type == 'price') {
        that.searchFrom.price = that.searchFrom.price == '' ? 'price' : ''
        that.searchFrom.sales = ''
      }
      that.getList()
    },
    /**
     * @Date:2024/3/29
     * @Author:wt
     * @Function:保存当前页面信息模块
     * @param:无
     */
    setLocation() {
      const location = this.$route
      let data = {
        fullPath: this.$route.fullPath,
        path: this.$route.path,
        query: this.$route.query
      }
      this.$Cookie.set('location', JSON.stringify(data))
    },
    /**
     * Author:wt
     * Date:2024/06/07
     * Function:筛选跳详情页
     * @param row
     */
    productJump(row) {
      this.productScreenShow = false
      const routePam = { marque: row.marque, productCategoryId: row.productCategoryId }
      let parObj = JSON.stringify(routePam)
      this.$router.push({
        path: '/website/shophome/detailjump',
        query: {
          product: parObj
        }
      })
    },
    /**
     * Author:wt
     * Date:2024/03/06
     * Function:搜索品牌
     * @param item
     */
    searchBrand(item) {
      let routeParam = JSON.parse(this.$route.query.product)
      let searchData = {
        distributorId: this.$Cookie.get('distributorId'),
        brand: item.brandId,
        orderType: 1,
        productCategoryId: routeParam.productCategoryId
      }
      getProductList(searchData).then(res => {
        if (res.code === 200) {
          let tempData = res.data
          if (tempData && tempData.length > 0) {
            this.tableData = tempData.map(item => {
              return {
                ...item,
                'purchaseAmount': 0
              }
            })
          } else {
            this.tableData = res.data
          }
        }
      })
    },
    // 货期选择
    handleHuoq(val, type) {
      var that = this
      if (type == 'spots') {
        if (val) {
          that.forwardStatus = false
        }
      } else if (type == 'forward') {
        if (val) {
          that.spotsStatus = false
        }
      }
      that.getList()
    },
    /**
     * Author:wt
     * Date:2024/03/06
     * Function:搜索系列
     * @param item
     */
    searchSeries(item) {
      let routeParam = JSON.parse(this.$route.query.product)
      let searchData = {
        distributorId: this.$Cookie.get('distributorId'),
        series: item,
        orderType: 1,
        productCategoryId: routeParam.productCategoryId
      }
      getProductList(searchData).then(res => {
        if (res.code === 200) {
          let tempData = res.data
          if (tempData && tempData.length > 0) {
            this.tableData = tempData.map(item => {
              return {
                ...item,
                'purchaseAmount': 0
              }
            })
          } else {
            this.tableData = res.data
          }
        }
      })
    },
    // // 排序搜索
    // sales() {
    //   let routeParam = JSON.parse(this.$route.query.product)
    //   let searchData = {
    //     distributorId: this.$Cookie.get('distributorId'),
    //     marque: routeParam.marque,
    //     orderType: 1,
    //     productCategoryId: routeParam.productCategoryId
    //   }
    //   getProductList(searchData).then(res => {
    //     if (res.code === 200) {
    //       let tempData = res.data
    //       if (tempData && tempData.length > 0) {
    //         this.tableData = tempData.map(item => {
    //           return {
    //             ...item,
    //             'purchaseAmount': 0
    //           }
    //         })
    //       }
    //     }
    //   })
    // },
    // price() {
    //   let routeParam = JSON.parse(this.$route.query.product)
    //   let searchData = {
    //     distributorId: this.$Cookie.get('distributorId'),
    //     marque: routeParam.marque,
    //     orderType: 2,
    //     productCategoryId: routeParam.productCategoryId
    //   }
    //   getProductList(searchData).then(res => {
    //     if (res.code === 200) {
    //       let tempData = res.data
    //       if (tempData && tempData.length > 0) {
    //         this.tableData = tempData.map(item => {
    //           return {
    //             ...item,
    //             'purchaseAmount': 0
    //           }
    //         })
    //       }
    //     }
    //   })
    // },

    /**
     * Author:wt
     * Date:2024/03/06
     * Function:增加采购数量
     * @param row
     */
    handleProductTotal(row) {

    },
    /**
     * Author:wt
     * Date:2024/03/06
     * Function:加入购物车
     * @param: 无
     */
    joinShopCar(row) {
      if (row.purchaseAmount <= 0) return this.$modal.msgWarning("请先选择购买的产品数量")
      const loginCheck = this.$Cookie.get('userName')
      if (loginCheck) {
        let user = this.$Cookie.get('user_info')
        const tempUserInfo = JSON.parse(user)
        // const nonZeroPurchase = this.tableData.filter(item => item.purchaseAmount !== 0);
        // const resultArray = nonZeroPurchase.map(item => {
        //   return {
        //     marque: item.marque,
        //     quantity: item.purchaseAmount
        //   };
        // });
        const queryParams = {
          shoppingCartProducts: [
            {
              marque: row.marque,
              quantity: row.purchaseAmount
            }],
          userId: tempUserInfo.userId
        }
        joinCar(queryParams).then((res) => {
          if (res.code === 200) {
            this.$parent.$parent.jumpShopping()
            this.$message.success('加入购物车成功')
            row.purchaseAmount = 0
          }
        })
      } else {
        this.$message.warning('请先登录')
      }

    },
  },

}
</script>

<style scoped lang="scss">
.product_screen {
  height: fit-content;
  width: 100%;
  background: #f5f7fa;
  border-radius: 10px;
  transform: translateX(3px);
  .bread {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
  }
  .searchproduct {
    width: 100%;
    height: fit-content;
    background: var(--bgColor);
    .search_brand {
      margin-top: 1px;
      width: 100%;
      display: flex;
      align-items: center;
      // justify-content: space-between;
      min-height: 50px;
      .search_brand_left {
        min-height: 50px;
        height: fit-content;
        width: 7%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .search_brand_right {
        width: 93%;
        background: #ffffff;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        .search_brand_right_text {
          display: flex;
          align-items: center;
          justify-content: center;
          min-height: 50px;
          margin: 0 20px;
          cursor: pointer;
        }
        // justify-content: center;
      }
      // flex-wrap: nowrap;
      // margin-bottom: 3px;
      // .brand_son {
      //   padding: 15px 15px;
      //   display: inline-block;
      //   margin-right: 5px;
      //   cursor: pointer;
      // }
      // .brand_son:hover {
      //   color: red;
      // }
    }
    .active {
      padding: 5px;
      // border: 1px solid #e6f5ff;
      background: #e6f5ff;
      color: red;
    }
  }

  .sort_catena {
    width: 100%;
    margin: 10px 0 10px 0;
    display: flex;
    flex-wrap: nowrap;
    .sort {
      display: flex;
      width: 100%;
      span {
        width: 95px;
        height: 30px;
        display: inline-block;
        border: 1px solid var(--bgColor);
        text-align: center;
        padding: 3px 0;
      }

      .sort_radio {
        .sort_hover {
          background: #f2f2f2;
          cursor: pointer;
        }
        .searchActive {
          background: rgb(0, 121, 254);
          color: white;
        }
        .sort_hover:hover {
          background: rgb(0, 121, 254);
          color: white;
        }
      }
    }
  }
  .table_proiimg {
    width: 100%;
    height: 110px;
    padding: 0px 10px;
    display: flex;
    flex-wrap: nowrap;
    background: white;
    cursor: pointer;
    .proImg {
      width: 68px;
      height: 100%;
      background: white;
      margin-right: 5px;
    }
  }
  .table_proinfo {
    width: 76%;
    height: 100%;
    background: white;
    font-size: 12px;
    // display: flex;
    // flex-wrap: wrap;
    .order_num {
      width: 100%;
      text-align: left !important;
      display: flex;
      flex-wrap: nowrap;
      align-items: start;
      .order_title {
        text-align-last: justify;
        display: inline-block;
        width: 50px;
      }
      .order_span {
        width: calc(100% - 80px);
        display: inline-block;
      }
      .model_span {
        display: inline-block;
        width: calc(100% - 80px);
        height: 100%;
      }
    }
  }
  .detail_table {
    ::v-deep .el-table__header-wrapper {
      .el-table__header {
        .el-table__cell {
          background: var(--bgColor);
          color: #000000;
          padding: 5px 0px !important;
          font-weight: normal;
          .cell {
            font-size: 12px;
          }
        }
      }
    }
    .el-table__body-wrapper {
      ::v-deep .el-table__cell {
        padding: 0 !important;
      }
    }
    ::v-deep.el-table__body-wrapper {
      ::v-deep.el-table__body {
        ::v-deep.el-table__row {
          ::v-deep.el-table__cell {
            border: 3px solid !important;
          }
        }
      }
    }
  }
}
.gwc {
  width: 130px;
  height: 30px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--bgColor);
  border-color: var(--bgColor);
  &:hover {
    background: var(--color-hover);
    border-color: var(--color-hover);
  }
  &:focus {
    background: var(--color-hover);
    border-color: var(--color-hover);
  }
}
.search_series {
  max-height: 250px;
  overflow: auto;
}
.search_series_right {
  max-height: 100px;
  overflow: auto;
}
</style>
